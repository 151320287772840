<template>
  <div class="container pd100">
    <div v-show="!loading">
      <van-cell title="课程信息" />
      <div class="card">
        <van-image class="card-media" :src="detail.course_cover" />
        <div class="card-inner">
          <div class="card-title ui-ellipsis">{{ detail.course_title }}</div>
          <div class="card-txt">{{detail.class_date}}</div>
        </div>
      </div>
      <div class="teacher mb10">
        <van-cell title="上课时间" :value="detail.class_date" />
        <van-cell title="上课老师" v-if="course_teacher.teacher_name" :value="course_teacher.teacher_name" />
        <van-cell title="上课学员" v-if="detail.course_student" :value="detail.course_student.student_name" />
        <!-- <van-cell title="上课老师" />
        <div class="teacher-inner">
          <div class="teacher-hd">
            <van-image class="teacher-avatar" :src="course_teacher.teacher_avatar" />
            <span class="teacher-title">{{ course_teacher.teacher_name }}</span>
          </div>
          <div class="teacher-bd" @click="onNavigator({path:'/teacher/detail?teacher_id='+ course_teacher.teacher_id})"><span>查看详情</span><van-icon name="arrow" /></div>
        </div> -->
      </div>
      <div class="form">
        <van-cell title="请假类型">
          <van-radio-group checked-color="#00cca2" direction="horizontal" v-model="cancel_type">
              <van-radio name="1" shape="round" icon-size="18">病假</van-radio>
              <van-radio name="2" shape="round" icon-size="18">事假</van-radio>
              <van-radio name="4" shape="round" icon-size="18">其他</van-radio>
          </van-radio-group>
        </van-cell>
        <!-- <van-field
          readonly
          clickable
          name="reason"
          :value="reason.label"
          label="请假类型"
          placeholder="点击选择请假类型"
          @click="showPicker = true"
        /> -->
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            value-key="label"
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <div class="box">
          <van-field
            v-model="remark"
            name="remark"
            rows="4"
            autosize
            type="textarea"
            maxlength="50"
            placeholder="请具体描述请假原因，不少于5个字"
          />
          <div class="image-tips">如因生病、参加学校集体活动等客观原因，请上传凭证</div>
          <van-field>
            <template #input>
              <van-uploader v-model="uploaImgs" :max-count="3" :after-read="afterRead" />
            </template>
          </van-field>
        </div>
      </div>
      <div class="affix-bar">
        <van-button type="primary" block @click="onSubmit">确认请假</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Uploader, Picker , RadioGroup, Radio } from 'vant'
import PageMixin from '@/mixins/page'
import { isEmpty } from '@/common/validate'
import upload from '@/utils/upload'

export default {
  name: 'StudentAskleave',
  components: {
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Picker.name]: Picker,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  filters: {
    formatEnd(value) {
      return value && value.slice(-8)
    }
  },
  mixins: [PageMixin],
  data() {
    return {
      detail: {},
      course_studio: {},
      course_teacher: {},
      cancel_type: "1",
      reason: {},
      // columns: [{ label: '生病', value: '1' }, { label: '学校组织活动', value: '2' }, { label: '外出有事', value: '3' }, { label: '其他', value: '4' }],
      columns: [{ label: '病假', value: '1' }, { label: '事假', value: '3' },{ label: '其他', value: '4' }],
      showPicker: false,
      remark: '',
      uploaImgs: [],
      loading:false
    }
  },
  created() {
    this.loading = true
    this.onDetail()
  },
  methods: {
    onDetail() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      this.$api.schedule_detail({ book_id: this.$route.query.book_id }).then(res => {
        this.detail = res.data
        this.course_studio = res.data.course_studio
        this.course_teacher = res.data.course_teacher
        this.loading = false
        this.$toast.clear()
      })
    },
    onConfirm(value, index) {
      this.reason = value
      this.showPicker = false
    },
    afterRead(file, detail) {
      file.status = 'uploading'
      file.message = '上传中...'
      upload({ file: file.file })
        .then(res => {
          this.uploaImgs.splice(detail.index, 1, { url: res.path, status: 'done', message: '上传成功' })
        })
        .catch(err => {
          console.error(err)
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
    onSubmit() {
      if (isEmpty(this.cancel_type)) {
        this.$toast('请选择请假原因')
        return
      }
      if (isEmpty(this.remark)) {
        this.$toast('请具体描述请假原因')
        return
      }
      this.$toast.loading({
        message: '提交中...',
        forbidClick: true
      })
      const params = {
        book_id: this.$route.query.book_id,
        cancel_reason: this.cancel_type,
        reason_des: this.remark,
        images: this.uploaImgs.map(item => item.url)
      }

      this.$api.schedule_checkout(params)
        .then(res => {
          this.$toast.success('申请请假成功')
          localStorage.setItem('schedule_refresh', true)
          setTimeout(() => {
            this.$router.back()
          }, 1500)
        })
        .catch(err => {
          this.$toast.fail('提交失败')
          console.error(err)
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .van-cell{
      position: relative;
      padding: 8px;
  }
  .van-cell-group{
      width: 96%;
      margin: 0 auto;
      border-radius: 10px;
      overflow: hidden;
      margin-top: 10px;
  }
  .van-cell__title{
      flex: 4;
  }
  .van-cell__label{
      display: flex;
      font-size: 11px;
      align-items: baseline;
  }
  .van-cell__value{
      flex: 2;
      min-width: 75%;
      span {
          display: inline-block;
          text-align: left;
          word-break: break-all;
      }
  }
  .van-checkbox--horizontal{
      margin-right: 0;
      margin-left: 12px;
  }
  .van-checkbox-group--horizontal{
      justify-content: flex-end;
  }
  .card{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    &-media{
      width: 130px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 10px;
    }
    &-inner{
      flex: 1;
      height: 60px;
    }
    &-title{
      max-width: 140px;
      font-size: 14px;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
  }
  .teacher{
    &-inner{
      padding: 10px 15px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 60px;
    }
    &-avatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
    }
    &-title{
      font-size: 14px;
      color: #333;
    }
    &-hd{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &-bd{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      color: #666;
    }
  }

  .image-tips{
    padding: 10px 16px;
    font-size: 14px;
    color: #666;
    background: #fff;
  }
</style>

